<template>
 <v-container>
    <div> Component 시험 페이지</div>

    <div>
      <h3> API call Sample </h3>
       <v-btn color="success" @click="goodLuck()">API Test (lotto)</v-btn> 
    </div>

    <v-data-table
        :headers="headers"
        :items="lotto_number_array"
        :items-per-page="5"
        class="elevation-1"
    ></v-data-table>
    <div>
      <h3> ARRAY Sample </h3>
       <v-btn color="green" @click="array()">TEST</v-btn> 
    </div>


    <div>
        <button @click="resetGraph()">Reset</button>
        <Bar
            id="myChart"
            ref="myChart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :plugins="plugins"
        />
    </div>
 </v-container>
</template>

<script>
  import Apis from '@/api/apis'

  import { Bar }  from 'vue-chartjs/legacy'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  import zoomPlugin from 'chartjs-plugin-zoom';

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, zoomPlugin)



  export default {
    name: 'ComponentTest',
    components: { Bar },
    props: {
      plugins: {
      type: Array,
      default: () => []
      }
    },

    methods: {
      async goodLuck() {
          await Apis.goodLuck({} ,(res) => {  // 정상처리 예제
            console.log("goodLuck result")
            console.log(res)

            let num_array = res['Your Lucky Numbers']
            // console.log(num_array)

            // num_array = [
            //   {col1: 1 ,col2: 2,col3: 3,col4: 4,col5: 5,col6: 6} 
            // ]

            // this.lotto_number_array = num_array.map(sub_array => sub_array.map((no,index) =>  { 
            //                                                                                      let key = `col${index+1}`
            //                                                                                      return {[key]: no} 
            //                                                                                  }
            //                                                                     )
            //                                         )
            this.lotto_number_array = num_array.map((sub_array) => {
                                                        let obj = {}
                                                        for (let i=0; i < 6; i++)
                                                          obj[`col${i+1}`] = sub_array[i]
                                                        return obj
                                                    })
            // console.log(this.lotto_number_array)
            this.$store.commit("resMessage","♡ It's Your Lucky Numbers !!! ♣ Good Luck ♣ ");


          }).catch( (err) => {  // 개별 API 오류 처리 예제
            console.log("GoodLuck 호출 오류")
            console.log(err)
          }) 
            console.log("GoodLuck!!!!")

      }, // goodLuck
      array() {
        let after_array =[]; 
        after_array = this.test_array;
        let a = after_array[1].push(1234.5);
        console.log("RESULT",after_array,a);
      } ,
      resetGraph() {
        this.$refs.myChart.chartInstance.resetZoom();
      },
    },  // -----------  methods 
    
    data() {
      // console.log(this)
      // console.log(this.$store)
      // console.log(this.$data)


      return {
        chartData: {
          labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
        },

        chartOptions: {
          responsive: true,
          indexAxis: 'y',
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true
                },
                mode: 'xy',
              }
            }
          }
        },

        headers: [
          { text: 'No 1', align: 'center', sortable: false, value: 'col1' },
          { text: 'No 2', align: 'center', sortable: false, value: 'col2' },
          { text: 'No 3', align: 'center', sortable: false, value: 'col3' },
          { text: 'No 4', align: 'center', sortable: false, value: 'col4' },
          { text: 'No 5', align: 'center', sortable: false, value: 'col5' },
          { text: 'No 6', align: 'center', sortable: false, value: 'col6' },
        ],
        lotto_number_array : [  

        ],
        test_array: [
          ['일자','temp', 'co2'],
          ['3:00 PM', 11, 3683.51],
          ['4:00 PM', 12, 3658.26],
          ['5:00 PM', 13, 4483.03],
          ['6:00 PM', 14, 4384.64],
          ['7:00 PM', 15, 4518.49],
          ['8:00 PM', 16, 4553.04],
          ['9:00 PM', 17, 4389.89],
          ['10:00 PM', 18, 4225.56],
        ],
      }
    },

  }
</script>
